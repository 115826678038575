import React from 'react'
import { useTranslate, useInput } from 'react-admin'
import { Chip, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { FilterIcon } from 'common/Icons'

import 'styles.scss'

interface Choice {
  id: string,
  name: string,
}

interface OutlinedSelectInputProps {
  source: string,
  choices: Choice[]
  label: string,
  alwaysOn: boolean,
  fieldProps?: SelectProps<string>,
}

export const OutlinedSelectInput = (props: OutlinedSelectInputProps) => {
  const { source } = props
  const { field } = useInput({ source: source })

  return <CustomSelectInput {...props} onChange={field.onChange} initValue={field.value}/>
}

interface CustomSelectInputProps {
  choices: Choice[]
  label: string,
  alwaysOn: boolean,
  fieldProps?: SelectProps<string>,
  initValue?: string
  onChange?: (value: string) => void
  fullWidth?: boolean
}

export const CustomSelectInput = ({ choices, label, fieldProps, initValue, onChange, fullWidth }: CustomSelectInputProps) => {
  const translate = useTranslate()
  const [selectedItem, setSelectedItem] = React.useState<string>(initValue || '')

  const handleItemSelect = (value: string) => {
    setSelectedItem(value)
    onChange && onChange(value)
  }

  return <FormControl className="custom-array-input margin">
    <InputLabel id="profile" shrink>{label}</InputLabel>
    <Select
      {...fieldProps}
      notched
      fullWidth
      label={label}
      value={selectedItem}
      onChange={e => handleItemSelect(e.target.value)}
      renderValue={(selected) => (
        <div>
          {
            <Chip
              key={selected}
              className="big-chip"
              label={translate(choices.find(c => c?.id === selected)?.name || "")}
              onDelete={() => { handleItemSelect('') }}
              deleteIcon={
                <FilterIcon
                  type="gray-x"
                  onMouseDown={(event) => event.stopPropagation()} />
              }
            />
          }
        </div>
      )}
    >
      {choices.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          <Chip
            key={id}
            className="squere-chip"
            label={translate(name)}
          />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
}