import React, { useEffect, useState } from 'react'
import { useTranslate } from "react-admin"
import Box from '@mui/material/Box'

import 'styles.scss'
import ActionButton from 'components/ActionButton'
import { CompanyPortalProperties, getCompanyPortalProperties, getCopanyPortalLogoPath } from 'api/company'
import RoundedPanel, { CrossCheckbox, Label } from 'common/RoundedPanel'
import { EditPortalProperties } from './companyModals/EditPortalProperties'
import { Chipify } from 'common/Chipify'
import { Grid } from '@mui/material'
import UserService from 'UserService'
import { UncacheableImg } from 'common/UncacheableImg'

const PortalProperties = ({ companyId }: { companyId: string }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState<boolean>(false)
  const [portalProperties, setPortalProperties] = useState<CompanyPortalProperties>()
  const [portalLogoPath, setPortalLogoPath] = useState<string>()

  const fetchPortalProperties = () => {
    getCompanyPortalProperties(companyId.toString())
      .then(properties => {
        setPortalProperties(properties)
        properties && getCopanyPortalLogoPath(companyId.toString())
          .then(logoPath => {
            setPortalLogoPath(logoPath)
          })
      })
  }
  useEffect(fetchPortalProperties, [])

  return <>
    {portalProperties != undefined && <>
      {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <>
        <EditPortalProperties
          companyId={companyId}
          portalProperties={portalProperties}
          open={open}
          onClose={() => setOpen(false)}
          onSave={fetchPortalProperties}
          portalLogoPath={portalLogoPath || null}
        />
        <Box className="edit-tab">
          <Box className='on-left'>
            <ActionButton onClick={() => { setOpen(true) }} >Edit</ActionButton>
          </Box>
        </Box>
      </>}
      {portalProperties &&
        <Box className="panel">
          <Box width="600px">
            <RoundedPanel>
              <>
                {portalLogoPath && <><UncacheableImg className="logo" src={portalLogoPath} alt="Application logo" /><hr /></>}
                <Label noSelect>Genre: </Label><Chipify source={[portalProperties.genre]} translation='dict.genre.' variant='squere-chip' /><br />
                <Label>Skillset node: </Label>{stripCategoryPrefix(portalProperties.taxonomy)}<br />
                <hr />
                <Label>Active languages: </Label>
                <Chipify source={portalProperties.allowedLanguageList.sort()} translation="dict.lang." variant='squere-chip' /><br />
                <hr />
                <Label>Translate&Create default language combination: </Label><br />
                {translate(`dict.lang.${portalProperties.defaultLanguageCombinationTranslateAndCreateFrom}`)} {"->"} {translate(`dict.lang.${portalProperties.defaultLanguageCombinationTranslateAndCreateTo}`)}
                <hr />
                <Label>Workflow default language combination: </Label><br />
                {translate(`dict.lang.${portalProperties.defaultLanguageCombinationFileFrom}`)} {"->"} {translate(`dict.lang.${portalProperties.defaultLanguageCombinationFileTo}`)}
                <hr />
                <Label>Enabled tablets:</Label><br />
                <Grid container spacing='5px' style={{ whiteSpace: 'nowrap' }} columns={2}>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showKmChat} id="showKmChat" />Enterprise ChatBot
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showKmGenerator} id="showKmGenerator" />AI Content Generator
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showInstant} disableRipple />Immediate Translation<br />
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showEnhance} id="showEnhance" />Enhanced Translation
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showTrustmine} id="showTrustmine" />Trust Mined Translation
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showBi} id="showBi" />BI
                  </Grid>
                  <Grid item xs={1}>
                    <CrossCheckbox checked={portalProperties.showDiscoverMe} id="showDiscoverMe" />Discover More
                  </Grid>
                </Grid>
              </>
            </RoundedPanel>
          </Box>
        </Box> || "No Portal Properties configured yet"}
    </>}
  </>
}

export default PortalProperties

export const stripCategoryPrefix = (text: string) => text.replace("Category:", "")

export const stripCategoryAndIQVIAPrefix = (text: string) => text.replace("Category:", "").replace("IQVIA:", "")